import { useCallback, useEffect } from "react";

import Cookies from "js-cookie";

import { dispatchErrorRoutine } from "shared/utils/dispatchErrorRoutine";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import openNotificationWithIcon from "shared/utils/openNotificationWithIcon";

const SOCIAL_SIGNIN_STORAGE_KEY = "has-attempt-to-social-signin";
const SOCIAL_LOGIN_FAILED_MESSAGE =
  "E-mail não autorizado para login ou cadastro na plataforma. Por favor, utilize um e-mail autorizado ou entre em contato com o suporte para mais informações.";

interface IUseMonitoringGoogleSigninProps {
  setSocialSigninAttempt: () => string;
  clearSocialSigninAttempt: () => void;
  verifyIfHasSocialSignAttempt: () => void;
}

const useMonitoringGoogleSignin = (): IUseMonitoringGoogleSigninProps => {
  const { isAuthenticated } = useAuthContext();

  const setSocialSigninAttempt = () =>
    Cookies?.set(SOCIAL_SIGNIN_STORAGE_KEY, "true");

  const clearSocialSigninAttempt = () =>
    Cookies?.remove(SOCIAL_SIGNIN_STORAGE_KEY);

  const verifyIfHasSocialSignAttempt = useCallback(() => {
    try {
      const hasAttempt = Boolean(Cookies?.get(SOCIAL_SIGNIN_STORAGE_KEY));
      if (!isAuthenticated && hasAttempt) {
        openNotificationWithIcon("error", SOCIAL_LOGIN_FAILED_MESSAGE);
      }

      clearSocialSigninAttempt();
    } catch (error) {
      dispatchErrorRoutine({
        error,
        errorMessage:
          "Error ~ useMonitoringGoogleSignin::verifyIfHasSocialSignAttempt",
        errorMessageToClient: SOCIAL_LOGIN_FAILED_MESSAGE,
      });
    }
  }, [isAuthenticated]);

  useEffect(
    function handleSocialSigninAttempt() {
      verifyIfHasSocialSignAttempt();
    },
    [verifyIfHasSocialSignAttempt],
  );

  return {
    setSocialSigninAttempt,
    clearSocialSigninAttempt,
    verifyIfHasSocialSignAttempt,
  };
};

export default useMonitoringGoogleSignin;
