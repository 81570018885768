import openNotificationWithIcon from "shared/utils/openNotificationWithIcon";

import { captureException, captureMessage } from "@sentry/nextjs";

interface IDispatchErrorRoutineProps {
  errorMessageToClient: string;
  errorMessage: string;
  error: Error;
}
export const dispatchErrorRoutine = ({
  errorMessageToClient,
  errorMessage,
  error,
}: IDispatchErrorRoutineProps) => {
  captureException(error);
  captureMessage(errorMessage);

  if (errorMessageToClient)
    openNotificationWithIcon("info", errorMessageToClient);
  console.log(errorMessage, error);
};
